@tailwind base;
@tailwind components;
@tailwind utilities;
@layer base {
  @import 'fonts.css';
  @import 'typography.css';
  @import 'btn.css';
  @import 'input.css';
  @import 'datepicker.css';
  @import 'phoneinput.css';
  @import 'richtext.css';

  html {
    @apply font-light tracking-[0.01em];
  }
}

body:has(#b8e-widget-container.closed) .scroll-up {
  @apply bottom-16 right-[1.25rem];
}

body:has(#b8e-widget-container.closed) #b8e-widget-container {
  @apply !bottom-2 !right-3;
}

body {
  @apply overflow-x-hidden;
}

body .container,
body .sm\:container {
  @apply max-w-screen-xxl;
}

.grecaptcha-badge {
  @apply hidden h-0 w-0;
}

.lazy-image:not(.lazy-loaded) img {
  @apply animate-pulse bg-grey5;
}

.gradient-bottom-to-top {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.082) 0%, rgba(0, 0, 0, 0.53) 39%);
}

.resort-nav-shadow {
  @apply shadow-[0px_1px_0px_0px_rgba(0,0,0,0.05),0px_5px_5px_0px_rgba(0,0,0,0.04),0px_12px_7px_0px_rgba(0,0,0,0.03),0px_21px_8px_0px_rgba(0,0,0,0.01),0px_33px_9px_0px_rgba(0,0,0,0.00)];
}
.resort-nav-shadow-mobile {
  @apply shadow-[0px_-1px_2px_0px_rgba(0,0,0,0.05),0px_-4px_4px_0px_rgba(0,0,0,0.04),0px_-9px_6px_0px_rgba(0,0,0,0.03),0px_-17px_7px_0px_rgba(0,0,0,0.01),0px_-26px_7px_0px_rgba(0,0,0,0.00)];
}
